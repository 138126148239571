.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: 20rem;
  font-weight: bold;
  color: white;
  position: relative;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 8rem;
  .hasStock {
    height: 30rem;
    background-color: rgba(0, 0, 0, 0.8);
    color: white;
    padding: 0 16rem;
    border-radius: 7rem 0 16rem 0;
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14rem;
    font-style: normal;
    font-weight: 800;
    line-height: 150%;
  }
  .investMira {
    height: 30rem;
    position: absolute;
    right: 16rem;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .image {
      height: 20rem;
      margin-right: 3rem;
    }
    .text {
      color: #0a0d10;
      -webkit-text-stroke-width: 0.5rem;
      -webkit-text-stroke-color: #fdfdfd;
      font-size: 20rem;
      font-style: normal;
      font-weight: 800;
      line-height: 150%;
    }
  }

  .image {
    height: calc(100% - 22rem);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    border-radius: 8rem 8rem 0 0;
    .name {
      margin-bottom: 12rem;
      padding: 0 24rem;
      text-align: center;
    }
  }
  .profile {
    height: 22rem;
    width: 100%;
    display: flex;
    font-size: 14rem;
    font-style: normal;
    font-weight: 800;
    line-height: 100%;
  }
  .riskLabel {
    border-radius: 0 0 0 8rem;
  }
  .returnLabel {
    border-radius: 0 0 8rem 0;
  }
  .riskLabel,
  .returnLabel {
    padding: 4rem 8rem;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.high {
  background-color: #ff005b;
}
.middle {
  background-color: #b79e3a;
}
.low {
  background-color: #00cc21;
}

.selected {
  height: calc(100% - 16rem);
  border: 8rem solid #e34084;
  border-radius: 16rem;
  // background-color: #e34084;
  .hasStock {
    background-color: #e34084;
  }
}
.unselected {
  border: 8rem solid transparent;
  border-radius: 16rem;
  height: calc(100% - 16rem);
}
