.outer {
  width: calc(100% - 208rem);
  height: calc(100% - 208rem);
  padding: 104rem;
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  .closeButton {
    position: absolute;
    top: 32rem;
    right: 32rem;
  }
  .container {
    width: 100%;
    height: 100%;
    max-width: 816px;
    max-height: 560px;
    padding: 32rem;
    border-radius: 8rem;
    border: 4rem solid var(--line, #4a5568);
    background: var(--Color-blackAlpha-90, rgba(0, 0, 0, 0.9));
    box-shadow: 0rem 0rem 16rem 0rem rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(4rem);
    .textArea {
      width: 100%;
      height: calc(50% - 40rem);
      padding-bottom: 40rem;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      .line {
        display: flex;
        justify-content: center;
        color: #cbd5e0;
        font-size: 28px;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
        .highlight {
          color: #fdfdfd;
          margin: 0 5px;
        }
      }
    }
    .imageArea {
      display: flex;
      justify-content: center;
    }
  }
}
