@use './styles/font.scss';

html {
  font-size: 1.2px;
}

@media screen and (max-width: 1440px) {
  html {
    font-size: 1.1px;
  }
}

@media screen and (max-width: 1024px) {
  html {
    font-size: 1px;
  }
}

@media screen and (max-width: 768px) {
  html {
    font-size: 0.8px;
  }
}

html,
body,
#root {
  margin: 0;
  font-family: 'M PLUS Rounded 1c';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
