.container {
  width: 100%;
  height: 100%;
  position: relative;
  .inner {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    .title {
      text-shadow: 0px 0px 52rem #fff;
      font-size: 31rem;
      font-weight: 700;
      line-height: 100%; /* 31px */
      letter-spacing: 6.82rem;
    }
    .subtitle {
      margin-top: 32rem;
      text-shadow: 0px 0px 52rem #fff;
      font-family: AB-countryroad;
      font-size: 71rem;
      font-weight: 400;
      line-height: 100%; /* 71px */
      letter-spacing: 49.7rem;
    }
    .result {
      margin-top: 64rem;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .logo {
      position: absolute;
      left: 0;
      bottom: 0;
      height: 256rem;
      width: 256rem;
    }
  }
}
