.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-weight: bold;
  font-size: 18rem;
  padding: 8rem 16rem;
  border-radius: 8rem;
  .title {
    font-size: 20rem;
  }
  .label {
    margin: 0 16rem;
  }
}
