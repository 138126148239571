.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 14rem;
  font-weight: 700;
  line-height: 150%;

  .contents {
    height: calc(100% - 200rem);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card {
  width: calc(80% - 48rem);
  max-width: 800rem;
  height: calc(100% - 64rem);
  max-height: 600rem;
  padding: 16rem 16rem 32rem 16rem;
  border-radius: 4rem;
  background-color: #0a0d10;
  border: 8rem solid transparent;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  .defaultImage {
    width: 100%;
    height: 200rem;
  }
  .imageContainer {
    position: relative;
    width: 100%;
    .image {
      width: 100%;
      height: 200rem;
    }
    .purchased {
      font-weight: 800;
      font-size: 14rem;
      line-height: 150%;
    }
    .overlay {
      position: absolute;
      padding: 0 16rem;
      height: 30rem;
      border-radius: 0rem 0rem 16rem 0rem;
      background-color: black;
      top: 0;
      left: 0;
    }
  }
  .titleArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    .title {
      font-size: 24rem;
      font-weight: 800;
      line-height: 150%;
    }
  }
  .labelArea {
    margin-top: 8rem;
    display: flex;
    justify-content: center;
    .label {
      margin: 0 4rem;
    }
  }
  .sentence {
    margin-top: 8rem;
    width: 100%;
  }
  .button {
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.selectedCard {
  /* 選択されたカードのスタイル */
  border: 8rem solid #58c2c7;
  /* その他のスタイルプロパティ */
}

.green {
  background-color: #00cc21;
}

.red {
  background-color: #ff005b;
}

.yellow {
  background-color: #e0b814;
}

.gray {
  background-color: #718096;
}
