.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  .header {
    display: flex;
    align-items: center;
    padding: 24rem;
    .left {
      display: flex;
      align-items: center;
      .name {
        margin-left: 22rem;
        font-size: 30rem;
        font-weight: 800;
        line-height: 150%;
      }
      .instrumentType {
        margin-left: 8rem;
        background: #1a202c;
        padding: 8rem 16rem;
        border-radius: 100rem;
        color: #fdfdfd;
        font-size: 12rem;
        font-weight: 800;
        line-height: 100%;
      }
    }
    .right {
      display: flex;
      align-items: center;
      margin-left: auto;
      .icon {
        height: 32rem;
        margin-right: 16rem;
        margin-bottom: auto;
        margin-top: 7rem;
      }
      .above {
        .currentPoint {
          font-family: VDL-LogoJrBlack;
          font-size: 48rem;
          font-weight: 950;
          line-height: 100%;
        }
      }
      .below {
        display: flex;
        justify-content: flex-end;
        .subPoint {
          font-size: 20rem;
          font-weight: 800;
          line-height: 100%;
        }
        .subPoint:not(:last-child) {
          margin-right: 8rem;
        }
      }
    }
  }
  .contents {
    width: 100%;
    margin-top: 8rem;
    height: calc(100% - 8rem);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .investmentTerm {
    width: 100%;
    max-width: 900rem;
    .investmentTermContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
      font-weight: bold;
      font-size: 18rem;
      padding: 8rem 16rem;
      border-radius: 8rem;
      .title {
        font-size: 20rem;
      }
      .label {
        margin: 0 16rem;
      }
      .buyRegend {
        width: 30rem;
        border-bottom: 2px dashed #00f5a0;
        margin-right: 8rem;
      }
    }
  }
  .investments {
    width: 100%;
    max-width: 900rem;
    display: flex;
    margin-top: 10rem;
    gap: 10rem;
    box-sizing: border-box;
    .investment {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: rgba(0, 0, 0, 0.9);
      color: white;
      font-weight: bold;
      font-size: 18rem;
      padding: 5rem 10rem;
      border-radius: 8rem;
      gap: 10rem;
      width: 100%;
      max-width: 33%;
      box-sizing: border-box;
      .investmentTitle {
        font-size: 16rem;
        font-weight: 800;
        line-height: 100%;
      }
      .investmentAbobe {
        text-align: right;
        .miniIcon {
          margin-right: 5rem;
          height: 14rem;
        }
      }
      .investmentBelow {
        font-size: 16rem;
        .subPoint {
          margin-right: 4rem;
        }
      }
    }
  }
  .chartContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 32rem;
    height: calc(100% - 300rem);
    .chart {
      max-width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
    }
  }
}

.newsOuter {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .newsInner {
    width: 80%;
  }
}

.regend {
  display: flex;
  align-items: center;
  margin-top: 32rem;
  .block {
    display: flex;
    align-items: center;
    margin: 0 16rem;
    .text {
      font-size: 18rem;
      font-weight: 800;
      line-height: 100%;
    }
    .buyRegend {
      width: 100rem;
      border-bottom: 2px dashed #00f5a0;
      margin-right: 16rem;
    }
  }
}
