.result {
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 16rem;
  width: calc(60% - 80rem);
  padding: 40rem;
  font-weight: bold;
  .line {
    display: flex;
    align-items: center;
    height: 66rem;
    .label {
      color: #cbd5e0;
      font-size: 30rem;
      font-weight: 800;
      line-height: 150%;
    }
    .point {
      color: #fdfdfd;
      text-align: right;
      /* Typography/Title/2xl */
      font-family: VDL-LogoJrBlack;
      font-size: 48rem;
      font-weight: 950;
      line-height: 100%;
      margin-left: auto;
      display: flex;
      align-items: center;
      img {
        height: 58rem;
        margin-right: 16rem;
      }
    }
  }
  .line:not(:first-child) {
    margin-top: 16rem;
  }
}
