.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  .header {
    display: flex;
    align-items: center;
    padding: 22rem;
    .left {
      display: flex;
      align-items: center;
    }
  }
  .statusContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .skillName {
      color: #fff;
      text-shadow: 0px 0px 44px rgba(0, 0, 0, 0.5);
      font-family: VDL-LogoJrBlack;
      font-size: 56rem;
      font-style: normal;
      font-weight: 950;
      line-height: 100%;
    }
    .statusName {
      min-height: 32rem;
      margin-top: 32rem;
      color: #fff;
      text-align: center;
      font-family: VDL-LogoJrBlack;
      font-size: 24rem;
      font-style: normal;
      font-weight: 950;
      line-height: 100%;
    }
    .badgeImage {
      margin: 16rem 0;
    }
    .statusMessageContainer {
      background-color: black;
      padding: 16rem 32rem;
      min-width: 640rem;
      border-radius: 16rem;
      .statusMessage {
        text-align: center;
        color: #fff;
        font-size: 24rem;
        font-style: normal;
        font-weight: 900;
        line-height: 150%;
      }
    }
    .statusArea {
      margin-top: 32rem;
    }
    .statusCountContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .badgeBlock {
      display: flex;
      justify-content: center;
    }
    .smallBadgeContainer {
      width: 80rem;
      margin: 0 4rem;
    }
    .badgeCountContainer {
      margin-top: 15rem;
      border-radius: 20rem;
      display: flex;
      align-items: end;
      justify-content: center;
      color: #0a0d10;
      padding: 8rem 12rem;
      .badgeCount {
        font-family: VDL-LogoJrBlack;
        font-size: 24rem;
        font-style: normal;
        font-weight: 950;
        line-height: 100%; /* 24px */
        letter-spacing: 2.88rem;
      }
      .badgeCountSmall {
        font-family: VDL-LogoJrBlack;
        font-size: 11rem;
        font-weight: 950;
        line-height: 100%;
        letter-spacing: 1.32rem;
      }
    }
  }
}
