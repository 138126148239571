.container {
  width: 100%;
  height: 100%;
  position: relative;
  .inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    .title {
      color: #fdfdfd;
      font-size: 36rem;
      font-weight: 800;
      line-height: 100%;
      margin-bottom: 24rem;
    }
    .subtitle {
      margin-bottom: 32rem;
      color: #fdfdfd;
      font-size: 24rem;
      font-weight: 800;
      line-height: 150%;
    }
    .buttonArea {
      margin-top: 58rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .button {
        margin: 0 8rem;
      }
    }
  }
}
