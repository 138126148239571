.container {
  display: flex;
  align-items: center;
  height: 74rem;
  .title {
    color: #ebf3f4;
    font-family: VDL-LogoJrBlack;
    font-size: 30rem;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 36rem */
    letter-spacing: 5.1rem;
    padding: 8rem 32rem;
    border-radius: 0 16rem 17rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #070404;
  }
  .parkButton {
    margin-left: 16rem;
  }
  .icon {
    width: 40rem;
    height: 40rem;
    margin-right: 8rem;
    margin-left: auto;
  }
  .userName {
    margin-right: 24rem;
    font-size: 16rem;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; /* 24rem */
  }

  .miraBlock {
    height: 100%;
    display: flex;
    align-items: center;
    margin-right: 32rem;
  }
}
