@use '../../styles/variables.scss' as variables;

.notInvestModal {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    width: 100%;
  }
  .activeHeader {
    width: 80%;
    max-width: 1000rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 24rem;
  }
  .contents {
    width: 100%;
    display: flex;
    height: calc(100% - 281rem);
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 32rem;
    .body {
      height: 100%;
      max-width: 1000rem;
      width: 80%;
      border-radius: 8rem;
      border: 3rem solid #00f5a0;
      background-color: rgba(0, 0, 0, 0.8);
      backdrop-filter: blur(10rem);
    }
  }
}
