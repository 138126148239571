/* KEYFRAMES */

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes spin3D {
  from {
    transform: rotate3d(0.5, 0.5, 0.5, 360deg);
  }
  to {
    transform: rotate3d(0deg);
  }
}

.containerOuter {
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 99999999999999;
  background-color: #fff;
}

.container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999999999999999;
}

.spinnerBox {
  width: 300rem;
  height: 300rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
}

/* SOLAR SYSTEM */

.solarSystem {
  width: 250rem;
  height: 250rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.orbit {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1rem solid #fafbfc;
  border-radius: 50%;
}

.earthOrbit {
  width: 165rem;
  height: 165rem;
  // animation: spin 12s linear 0s infinite;
  animation: spin 3s linear 0s infinite;
}

.venusOrbit {
  width: 120rem;
  height: 120rem;
  // animation: spin 7.4s linear 0s infinite;
  animation: spin 2s linear 0s infinite;
}

.mercuryOrbit {
  width: 90rem;
  height: 90rem;
  // animation: spin 3s linear 0s infinite;
  animation: spin 1s linear 0s infinite;
}

.planet {
  position: absolute;
  top: -5rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-color: #3ff9dc;
}

.sun {
  width: 35rem;
  height: 35rem;
  border-radius: 50%;
  background-color: #ffab91;
}

.leo {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.blue-orbit {
  width: 165rem;
  height: 165rem;
  border: 1rem solid #91daffa5;
  animation: spin3D 3s linear 0.2s infinite;
}

.green-orbit {
  width: 120rem;
  height: 120rem;
  border: 1rem solid #91ffbfa5;
  animation: spin3D 2s linear 0s infinite;
}

.red-orbit {
  width: 90rem;
  height: 90rem;
  border: 1rem solid #ffca91a5;
  animation: spin3D 1s linear 0s infinite;
}

.white-orbit {
  width: 60rem;
  height: 60rem;
  border: 2rem solid #ffffff;
  animation: spin3D 10s linear 0s infinite;
}

.w1 {
  transform: rotate3D(1, 1, 1, 90deg);
}

.w2 {
  transform: rotate3D(1, 2, 0.5, 90deg);
}

.w3 {
  transform: rotate3D(0.5, 1, 2, 90deg);
}

.three-quarter-spinner {
  width: 50rem;
  height: 50rem;
  border: 3rem solid #fb5b53;
  border-top: 3rem solid transparent;
  border-radius: 50%;
  animation: spin 0.5s linear 0s infinite;
}
