.container {
  display: flex;
  flex-direction: column;
  input {
    border-radius: 5rem;
    text-align: right;
    outline: none;
    border: 0.5rem solid #e2e8f0;
  }
  input:disabled {
    color: #aaaaaa !important;
  }
}
