.container {
  width: 100%;
  height: 100%;
  .investment {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .half {
      width: calc(50% - 8rem);
      height: 100%;
    }
    .half:first-child {
      margin-right: 8rem;
    }
    .half:last-child {
      margin-left: 8rem;
    }
    .full {
      padding: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
    }
  }
}
