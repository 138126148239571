.modal {
  height: 100%;
  width: 100%;
  position: relative;
  .closeIcon {
    position: absolute;
    top: 0;
    z-index: 9;
    right: 1rem;
    font-size: 4rem;
  }
}
