.container {
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  color: white;
  padding: 16rem;
  border-radius: 8rem;
  .block {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    margin-right: 16rem;
    padding-right: 16rem;
    .label {
      font-size: 12rem;
    }
    .content {
      font-weight: 950;
      margin-left: auto;
      display: flex;
      align-items: center;
      line-height: 120%;
      font-family: VDL-LogoJrBlack;
      word-break: break-all;
    }
    .goalContent {
      font-size: 14rem;
      margin: auto 0 auto auto;
      display: flex;
      align-items: center;
    }
    .icon {
      margin-right: 0.5rem;
    }
  }
  .blockMira {
    min-width: calc(35% - 32rem);
    .content {
      font-size: 30rem;
    }
  }
  .blockQuantitativeGoal {
    min-width: calc(25% - 32rem);
    .content {
      font-size: 14rem;
    }
  }
  .blockQualitativeGoal {
    min-width: calc(40% - 32rem);
    .content {
      font-size: 14rem;
    }
  }
  .block:not(:last-child) {
    border-right: dashed 1rem #4a5568;
  }
}
