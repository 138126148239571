.container {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  .labelWrapperRed,
  .labelWrapperGreen,
  .labelWrapperOrange {
    min-width: 0.5rem;
    padding: 0.5rem 0.7rem;
  }
  .label {
    margin: 0 0.5rem;
    padding: 2rem 5rem;
    border-radius: 4rem;
    font-weight: 800;
    font-size: 14rem;
  }
  .red {
    background-color: #ff005b;
  }
  .green {
    background-color: #00cc21;
  }
  .yellow {
    background-color: #e0b814;
  }
  .gray {
    background-color: #718096;
  }
}
