.card {
  width: calc(100% - 32rem);
  color: #e2e8f0;
  padding: 16rem;
  border-radius: 8rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: #a0aec0;
  font-size: 12rem;
  font-weight: 800;
  line-height: 100%;
  .line {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    .eventDate {
      font-size: 12rem;
      font-weight: 800;
      line-height: 100%;
    }
    .has {
      background-color: #33179c;
      padding: 2rem 16rem;
      color: white;
      border-radius: 48rem;
      font-size: 10rem;
      font-weight: 800;
      line-height: 100%;
      margin-left: 8rem;
    }
  }
  .sentence {
    margin-top: 8rem;
    color: #e2e8f0;
    font-size: 12rem;
    font-weight: 500;
    line-height: 150%;
    word-wrap: break-word;
    overflow-wrap: anywhere;
    display: flex;
    align-items: center;
    .button {
      margin-left: auto;
    }
  }
}
