@use '../../styles/variables.scss' as variables;

.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  .contents {
    height: calc(100% - 100rem);
    display: flex;
    justify-content: center;
    .cardArea {
      height: 100%;
      width: 80%;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      .card {
        margin: 8rem;
        width: calc(100% / 3 - 16rem);
        max-width: 300rem;
        height: 200rem;
      }
    }
  }
}

.footer {
  position: absolute;
  right: 0;
  bottom: 0;
  background: linear-gradient(0deg, #000 37.26%, rgba(0, 0, 0, 0) 100%);
  padding: 18rem 50rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: bold;
  .label {
    font-size: 20rem;
    font-weight: 800;
    line-height: 150%;
    margin-bottom: 9rem;
  }
  .quantity {
    font-family: VDL-LogoJrBlack;
    font-size: 42rem;
    font-weight: 950;
    line-height: 100%;
  }
}
