.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 16rem;
  .title {
    font-size: 45rem;
    font-family: AB-countryroad;
    letter-spacing: 10rem;
  }
  .formContainer {
    margin: 32rem;
    padding: 16rem;
    border-radius: 4rem;
    background-color: #fff;
    width: 50%;
    .form {
      margin: 2rem;
    }
  }
}
