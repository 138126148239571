button {
  padding: 0 8rem;
  font-size: 14rem;
  font-weight: 800;
  border: none;
  border-radius: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #0a0d10;
  font-family: 'M PLUS Rounded 1c';
  line-height: 100%;
}
.lightGreen {
  background: linear-gradient(243deg, #00f5a0 6.12%, #00d9f5 95.89%);
  box-shadow: 0rem 0rem 28rem 0rem #00c5c9;
}
.transparent {
  color: #fdfdfd;
  border: 3rem solid #e2e8f0;
  background: radial-gradient(50% 50% at 50% 50%, #173d49 0%, #0a0d10 100%);
}
.white,
.whiteNoShadow {
  background-color: #edf2f7;
}
.white {
  box-shadow: 0rem 0rem 28rem 0rem #00c5c9;
}

.red {
  background-color: #d53f8c;
}

.leftIcon {
  margin-right: 6rem;
}
.rightIcon {
  margin-left: 6rem;
}

.leftIcon,
.rightIcon {
  height: 14rem;
}
.transparentGreen {
  background: linear-gradient(180deg, #000 51.91%, rgba(0, 0, 0, 0) 100%);
  border-radius: 4rem;
  border: 1rem solid var(--color-main-chien, #58c2c7);
  color: #58c2c7;
}

.disabled {
  opacity: 0.5;
}
