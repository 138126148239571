.container {
  width: calc(100% - 124rem);
  height: calc(100% - 60rem);
  color: #cbd5e0;
  padding: 30rem 62rem;
  font-size: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .innerContainer {
    max-width: 1000rem;
    width: calc(100% - 124rem);
    height: calc(100% - 60rem);
    padding: 30rem 62rem;
    background-color: rgba(0, 0, 0, 0.85);
    display: flex;
    flex-direction: column;
    .contents {
      width: 100%;
      height: calc(100% - 70rem);
      max-height: calc(100% - 50rem);
      overflow: auto;
    }
    .title {
      font-family: VDL-LogoJrBlack;
      color: #edf2f7;
      font-size: 36rem;
      font-style: normal;
      font-weight: 950;
      line-height: 120%;
      margin: 16rem 0;
      padding-bottom: 32rem;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: solid 1rem #a0aec0;
    }
    .label {
      font-weight: bold;
    }
    .content {
      margin-left: auto;
    }
    .holdMira {
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        margin-left: 21rem;
        margin-right: 8rem;
      }
    }
  }
  .line {
    display: flex;
    align-items: center;
    position: relative;
    .textLeft {
      font-size: 21rem;
      margin-right: 16rem;
    }
    .textRight {
      font-size: 21rem;
      margin-left: 16rem;
    }
    .balloon {
      position: absolute;
      padding: 10rem 24rem;
      background-color: #444;
      bottom: 110rem;
      right: 0;
      transform: translateX(-50%);
      border-radius: 5rem;
      display: flex;
      justify-content: center;
      font-size: 16rem;
      font-style: normal;
      font-weight: 800;
      line-height: 100%; /* 16rem */
    }
    .balloon:before {
      content: '';
      position: absolute;
      top: 100%;
      right: 5rem;
      margin-left: -20rem;
      border: 20rem solid transparent;
      border-top: 20rem solid #444;
      transform: translateX(-50%); /* 矢印を吹き出しの中心に */
    }
    .error {
      color: pink;
    }
  }

  .buttonArea {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: auto;
    padding-top: 20rem;
    .button {
      margin: 0 8rem;
    }
  }
}

.parts {
  border-bottom: dashed 1rem #4a5568;
  margin-top: 16rem;
  padding-bottom: 16rem;
}

.optionArea {
  min-height: 25rem;
  margin-top: 8rem;
  font-size: 16rem;
}

// TODO あとでコンポーネントにしたい
.select {
  background-color: rgb(10, 13, 16);
  color: white;
  padding: 24rem 32rem;
  font-size: 24rem;
  font-weight: 800;
  line-height: 150%;
  max-width: 600rem;
  border-radius: 5rem;
  outline: none;
  border: 0.5rem solid #e2e8f0;
}
