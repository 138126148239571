.container {
  width: 100%;
  height: 100%;
  position: relative;
  .innerContainer {
    font-size: 30rem;
    font-weight: 800;
    line-height: 100%;
    color: white;
    padding: 0 100rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: calc(100% - 200rem);
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    left: 0;
    top: 0;
    .title {
      margin: 1rem 0;
      font-size: 36rem;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
    }

    .contents {
      background-color: #1a202c;
      border: solid 2rem #58c2c7;
      border-radius: 4rem;
      padding: 48rem 64rem;
      width: calc(100% - 128rem);
      margin: 48rem 0;
      .line {
        display: flex;
        align-items: center;
        .block {
          margin-left: auto;
          display: flex;
          align-items: center;
        }
        .point {
          font-family: VDL-LogoJrBlack;
          font-size: 48rem;
          font-weight: 950;
          line-height: 100%;
          margin-left: 18rem;
        }
        .remainingAmount {
          font-family: VDL-LogoJrBlack;
          font-size: 36rem;
          font-weight: 950;
          line-height: 120%;
          margin: 0 18rem;
        }
      }
      .line:nth-child(2) {
        margin-top: 32rem;
      }
    }

    .buttonArea {
      display: flex;
      .button {
        margin: 0 12rem;
      }
    }
  }
  .icon {
    height: 60rem;
  }
}
