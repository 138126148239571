.container {
  width: calc(100% - 80rem);
  height: calc(100% - 48rem);
  padding: 24rem 40rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  .noRecord {
    display: flex;
    align-items: center;
    justify-content: center;
    .noRecordMessgae{
      margin-top: 20rem; 
      color: #fdfdfd;
      font-size: 32rem;
      font-weight: 800;
      text-align: center; 
    }
  }
  .record {
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.9);
    width: calc(100% - 32rem);
    padding: 8rem 16rem;
    border-radius: 12rem;
    margin-top: 16rem;
    .investmentTermName {
      color: #fdfdfd;
      font-size: 20rem;
      font-weight: 800;
      line-height: 100%;
    }
    .instrumentType {
      margin-left: 8rem;
      background: #1a202c;
      padding: 8rem 16rem;
      border-radius: 100rem;
      color: #fdfdfd;
      font-size: 12rem;
      font-weight: 800;
      line-height: 100%;
    }
    .statusArea {
      display: flex;
      align-items: center;
      margin-left: auto;
      .badgeIcon {
        width: 40rem;
        height: 40rem;
        margin-right: 10rem;
      }
      .statusName {
        color: #fff;
        font-size: 14rem;
        font-weight: 800;
        line-height: 100%;
      }
      .clearCount {
        margin-top: 4rem;
        color: #cbd5e0;
        font-size: 12rem;
        font-weight: 800;
        line-height: 100%;
      }
    }
    .pointArea {
      width: 170rem;
      margin-left: auto;
      text-align: right;
      font-weight: 800;
      .totalCurrentPointContainer {
        font-size: 30rem;
        font-weight: 800;
        line-height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        .totalCurrentPoint {
          display: flex;
          justify-content: end;
          align-items: center;
        }
        .miraIcon {
          width: 21rem;
          height: 21rem;
          margin-right: 3rem;
        }
      }
      .totalSubArea {
        font-size: 14rem;
        font-weight: 800;
        line-height: 150%;
        display: flex;
        align-items: center;
        justify-content: end;
        .totalYield {
          margin-left: 6rem;
          display: flex;
        }

        .totalReturn {
          display: flex;
        }
      }
    }
  }
}
