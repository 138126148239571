.container {
  width: 100%;
  top: 20rem;
  left: 0;
  position: absolute;
  display: flex;
  justify-content: center;
  z-index: 1;
  .inner {
    width: 80%;
    border-radius: 8rem;
    padding: 12rem 24rem;
    background: rgba(0, 0, 0, 1);
    display: flex;
    align-items: center;
    color: #ff005b;
    font-size: 16rem;
    font-weight: 800;
    .messageArea {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(100% - 100rem);
      .message {
        display: flex;
        align-items: center;
        .icon {
          margin-right: 3rem;
        }
      }
    }
    .button {
      margin-left: auto;
    }
  }
}
