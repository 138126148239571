.container {
  width: calc(100% - 48rem);
  height: calc(100% - 48rem);
  padding: 24rem;
  display: flex;
  flex-direction: column;
}

.currentPointContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16rem;
}

.currentPointTitle {
  color: #fff;
  font-size: 24rem;
  font-weight: 800;
  line-height: 150%;
}

.currentPoint {
  font-family: VDL-LogoJrBlack;
  font-size: 48rem;
  font-weight: 950;
  line-height: 100%;
}

.miraIcon {
  width: 52rem;
  height: 52rem;
  margin-right: 6rem;
}

.miraIconContainer {
  display: flex;
  align-items: center;
  margin-top: 4rem;
}

.productAreaContainer {
  display: flex;
  justify-content: space-between;
  height: calc(100% - 84rem);
  .card {
    height: 100%;
    width: calc(25% - 6rem);
  }
}
