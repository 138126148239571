.container {
  // TODO
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.container::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: calc(100% + 10px);
  height: calc(100% + 10px);
  background-image: url('./static/images/background.png');
  background-size: cover;
  filter: blur(5px);
  margin: -5px;
}
