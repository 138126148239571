.container {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 800;
  line-height: 100%; /* 16rem */

  .label {
    margin-right: 12rem;
    font-size: 14rem;
  }
  .mira {
    font-size: 24rem;
    display: flex;
    align-items: center;
  }
  .mira::after {
    content: 'ミラ';
    font-size: 16rem;
    margin-left: 6rem;
  }
}
