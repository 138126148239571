.button {
  font-family: 'vdl-logojrblack', sans-serif;
  font-weight: 950;
  font-style: normal;
  font-size: 22rem;
  line-height: 100%;
  letter-spacing: 8.8rem;
  width: 256rem;
  height: 44rem;
  border-radius: 100rem;
  border: none;
  overflow: hidden;
  position: relative;
  .dummy {
    color: transparent;
  }
  .block {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    position: absolute;
    height: 100%;
    top: 0;
    right: 0;
  }
}

// .green {
//   background: var(--linear, linear-gradient(220deg, #00f5a0 0%, #00d9f5 100%));
// }
