@use '../../styles/variables.scss' as variables;

// TODO 色は最後外だしする!!!!
.container {
  width: calc(100% - 80rem);
  height: calc(100% - 48rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24rem 40rem;
  .header {
    width: 100%;
  }
  .activeHeader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .investmentTerm {
    width: 100%;
  }
  .goal {
    margin-top: 4rem;
    width: 100%;
  }
  .investmentSetting {
    width: 100%;
    height: calc(100% - 150rem);
    margin-top: 24rem;
  }
  .investmentDoing {
    width: 100%;
    height: calc(100% - 150rem);
    margin-top: 24rem;
  }
}
