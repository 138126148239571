.container {
  position: absolute;
  height: calc(100rem - 48rem);
  padding: 24rem 32rem;
  width: calc(100% - 64rem);
  bottom: 0;
  left: 0;
  background: linear-gradient(0deg, #000 37.26%, rgba(0, 0, 0, 0) 100%);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: #cbd5e0;
  font-size: 24rem;
  font-style: normal;
  font-weight: 800;
  line-height: 150%;
  .title {
    margin-right: auto;
  }
  .form {
    align-items: center;
    display: flex;
    .mira {
      margin-left: 12rem;
    }
    .textForm {
      position: relative;
    }
    .balloon {
      color: #fdfdfd;
      position: absolute;
      padding: 16rem 24rem;
      background-color: #444;
      bottom: 75rem;
      border-radius: 5rem;
      font-size: 16rem;
      font-style: normal;
      font-weight: 800;
      line-height: 100%;
      display: flex;
      align-items: center;
      span {
        white-space: nowrap;
      }
      .point {
        margin-right: 8rem;
        font-family: VDL-LogoJrBlack;
        font-size: 30rem;
        font-style: normal;
        font-weight: 950;
        line-height: 120%;
      }
    }
    .balloon:before {
      content: '';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -20rem;
      border: 20rem solid transparent;
      border-top: 20rem solid #444;
    }
    .error {
      color: #e34084;
    }
  }
  .button {
    margin-left: 35rem;
  }
}
