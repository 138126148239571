.card {
  height: calc(100% - 48rem);
  width: calc(100% - 48rem);
  border-radius: 4rem;
  padding: 16rem 16rem 16rem 16rem;
  border: 8rem solid transparent;
  background-color: #0a0d10;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14rem;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  .image {
    width: 100%;
  }
  .imageContainer {
    position: relative;
    width: 100%;
    .purchased {
      font-size: 14rem;
      font-weight: 800;
      line-height: 150%;
    }
    .overlay {
      position: absolute;
      height: 33rem;
      padding: 0 16px;
      border-radius: 0rem 0rem 16rem 0rem;
      background-color: black;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .titleArea {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    .title {
      font-size: 19rem;
      font-weight: 800;
      line-height: 150%;
      text-align: center;
    }
  }
  .labelArea {
    margin-top: 4rem;
    display: flex;
    justify-content: center;
    .label {
      margin: 0 4rem;
    }
  }
  .sentence {
    margin-top: 8rem;
    font-size: 13rem;
  }
  .button {
    width: 80%;
    margin-top: auto;
    margin-bottom: 2rem;
  }
}

.selectedCard {
  border-radius: 4rem;
  border: 8rem solid #58c2c7;
}

.label {
  margin: 0 0.5rem;
  padding: 2rem 5rem;
  border-radius: 4rem;
}

.green {
  background-color: #00cc21;
}

.red {
  background-color: #ff005b;
}

.yellow {
  background-color: #e0b814;
}
