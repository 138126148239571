.productArea {
  padding: 16rem;
  width: calc(100% - 32rem);
  height: calc(100% - 32rem);
  color: white;
  background-color: #171923;
  display: flex;
  flex-direction: column;
  .productTitle {
    text-align: center;
    color: #fdfdfd;
    font-size: 16rem;
    font-weight: 800;
    line-height: 100%;
    background: #4a5568;
    padding: 4rem 16rem;
    border-radius: 100rem;
    width: calc(100% - 32rem);
  }
  .totalMira {
    text-align: center;
    margin-top: 8rem;
    color: #a0aec0;
    font-size: 14rem;
    font-weight: 800;
    line-height: 150%;
  }
  .totalMiraContent {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8rem;
    .totalReturnPoint {
      font-size: 20rem;
      font-family: VDL-LogoJrBlack;
      font-weight: 950;
      line-height: 120%;
    }
  }
  .miraIcon2 {
    width: 20rem;
    height: 20rem;
    margin-right: 5rem;
  }
  .dashed {
    border: 2rem dashed #2d3748;
    width: 100%;
    margin: 16rem 0;
  }
  .badgeContainer {
    position: relative;
    display: flex;
    justify-content: center;
    .badgeName {
      position: absolute;
      bottom: 0;
      left: auto;
      right: auto;
      color: #fff;

      font-family: VDL-LogoJrBlack;
      font-size: 14rem;
      font-style: normal;
      font-weight: 950;
      line-height: 120%;
    }
  }
  .statusContainer {
    width: 100%;
  }
  .statusCountContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8rem 0;
    .button {
      margin-left: 8rem;
    }
  }
  .statusCount {
    display: flex;
    align-items: end;
    justify-content: center;
    font-weight: 950;
    -webkit-text-stroke-width: 2;
    -webkit-text-stroke-color: #000;
    font-family: VDL-LogoJrBlack;
    font-size: 32rem;
    font-weight: 950;
    line-height: 100%; /* 32px */
    letter-spacing: 3.2rem;
    .numerator {
      -webkit-text-stroke-width: 2;
      -webkit-text-stroke-color: #000;
      font-family: VDL-LogoJrBlack;
      font-size: 32rem;
      font-weight: 950;
      line-height: 100%; /* 32px */
      letter-spacing: 3.2rem;
    }
    .denominator {
      color: #fff;
      font-family: VDL-LogoJrBlack;
      font-size: 14rem;
      font-weight: 950;
      line-height: 100%;
      letter-spacing: 1.4rem;
    }
  }
  .incrementCountContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16rem;
    font-weight: 800;
    line-height: 150%;
    background-color: #2d3748;
    padding: 2rem 8rem;
    border-radius: 4rem;
    margin-top: auto;
  }
  .bankContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16rem;
    height: 100%;
    .bankMessage {
      text-align: center;
      color: #a0aec0;
      font-size: 17rem;
      font-style: normal;
      font-weight: 800;
      line-height: 120%;
    }
  }
}
