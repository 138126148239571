.container {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  .title {
    color: #000;
    text-shadow: 0 0 44rem #fff;
    font-family: AB-countryroad;
    font-size: 71rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
  }
  .badge {
    height: 205rem;
    width: 205rem;
    margin: 64rem 0;
  }
  .sentence {
    min-width: 900rem;
    padding: 32rem;
    border-radius: 16rem;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .status {
      color: #fff;
      font-family: VDL-LogoJrBlack;
      font-size: 30rem;
      font-weight: 400;
      line-height: 120%; /* 36px */
      letter-spacing: 3rem;
    }
    .statusSummary {
      margin-top: 24rem;
      color: #fff;
      font-size: 24rem;
      font-weight: 900;
      line-height: 150%;
    }
  }
}
