.container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);

  .contents {
    height: calc(100% - 200rem);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .card {
      width: 30%;
      height: 100%;
      margin: 0 1%;
      max-width: 300rem;
      max-height: 600rem;
    }
  }
}
