.container {
  border-radius: 8rem;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  display: flex;
  align-items: center;
  width: calc(100% - 32rem);
  height: calc(100% - 16rem);
  padding: 8rem 16rem;
  .left,
  .right {
    font-size: 16rem;
    font-weight: 800;
    line-height: 100%;
  }
  .icon {
    margin-right: 12rem;
    height: 21rem;
    width: 21rem;
  }
  .mira {
    font-size: 20rem;
    font-weight: 800;
    line-height: 100%;
  }
  .setting,
  .doing {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }
  .doing {
    .right {
      .pointBlock {
        display: flex;
        flex-direction: column;
        .above {
          display: flex;
          align-items: center;
          .icon {
            margin-right: 3rem;
            height: 15.4rem;
            width: 15.4rem;
          }
          .mira {
            font-size: 16.8rem;
            font-weight: 800;
            line-height: 150%;
          }
        }
        .below {
          display: flex;
          .mira {
            font-size: 14rem;
            font-weight: 800;
            line-height: 150%;
          }
          .mira:nth-child(2) {
            margin-left: 6rem;
          }
        }
      }
      .graph {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 12rem;
      }
    }
  }
}

.plus {
  color: #ff005b;
}
.minus {
  color: #00cc21;
}
.zero {
  color: #ddd;
}
