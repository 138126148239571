.container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .body {
    width: calc(70% - 80rem);
    height: calc(100% - 80rem);
    padding: 40rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .header {
      display: flex;
      align-items: center;
      img {
        height: 175rem;
        width: 175rem;
        object-fit: cover;
        border-radius: 8rem;
      }
      .title {
        font-size: 30rem;
        font-style: normal;
        font-weight: 800;
        line-height: 150%;
        margin-left: 16rem;
        color: #1a202c;
        font-weight: bold;
      }
    }
    .sentence {
      margin-top: 16rem;
      .label {
        font-size: 20rem;
        font-weight: 800;
        line-height: 100%;
        border-bottom: dashed 1rem #1a202c;
        padding-bottom: 8rem;
        color: #1a202c;
        overflow-wrap: break-word;
      }
      .text {
        padding: 16rem 32rem;
        color: #4a5568;
        font-size: 16rem;
        font-weight: 800;
        line-height: 150%;
        overflow-wrap: break-word;
      }
    }
  }
}
