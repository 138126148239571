.container {
  padding: 16rem;
  width: calc(100% - 32rem);
  height: calc(100% - 32rem);
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 8rem;

  .title {
    font-size: 16rem;
    font-weight: 800;
    line-height: 150%;
    color: white;
  }
  .cardContainer {
    margin-top: 16rem;
    height: calc(100% - 40rem);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    .card {
      width: 100%;
    }
    .card:not(:first-child) {
      margin-top: 8rem;
    }
  }
}
