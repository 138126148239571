@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('../../public/font/M_PLUS_Rounded_1c/MPLUSRounded1c-Regular.ttf');
  font-weight: normal;
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('../../public/font/M_PLUS_Rounded_1c/MPLUSRounded1c-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('../../public/font/M_PLUS_Rounded_1c/MPLUSRounded1c-Thin.ttf');
  font-weight: 100;
}

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('../../public/font/M_PLUS_Rounded_1c/MPLUSRounded1c-ExtraBold.ttf');
  font-weight: 900;
}
