.container {
  position: fixed;
  bottom: 0;
  width: calc(100% - 122rem - 190rem);
  background: rgba(11, 5, 5, 0.7);
  backdrop-filter: blur(5rem);
  background-color: rgba(0, 0, 0, 0.8);
  padding: 18rem 122rem 18rem 190rem;
  .imageContainer {
    position: relative;
    img {
      position: absolute;
      height: 140rem;
      bottom: -60rem;
      left: -170rem;
    }
  }
  .messageContainer {
    width: 100%;
    height: 60rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 20rem;
    font-style: normal;
    font-weight: 800;
    line-height: 150%; /* 30rem */
  }
}
