.badgeOuter {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.9);
  .badge {
    width: 85%;
    height: 85%;
  }
}

.disabled {
  font-family: VDL-LogoJrBlack;
  font-size: 32rem;
}
