.container {
  width: 100%;
  height: 100%;
  .investment {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .card {
      width: calc(25% - 6rem);
      height: 100%;
    }
    .card:not(:last-child) {
      margin-right: 8rem;
    }

    .selected {
      width: calc(60% - 8rem);
      height: calc(100%);
      margin-right: 8rem;
      .selectedCard {
        height: 100%;
        width: 100%;
      }
    }
    .news,
    .unselected {
      margin-left: 8rem;
      width: calc(40% - 8rem);
      height: calc(100%);
    }
    .unselected {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .unselectedCard {
        height: calc(100% / 3 - 32rem / 3);
        width: 100%;
        opacity: 0.5;
      }
      .unselectedCard:not(:last-child) {
        margin-bottom: 16rem;
        opacity: 0.5;
      }
    }
  }
}
