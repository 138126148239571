.container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  border-radius: 8rem;
  overflow: auto;
  .image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .selectedTitle,
  .title {
    font-weight: 900;
    font-size: 24rem;
    text-shadow: 3rem 3rem 0rem #000, -3rem -3rem 0rem #000,
      -3rem 3rem 0rem #000, 3rem -3rem 0rem #000, 3rem 0rem 0rem #000,
      -3rem -0rem 0rem #000, 0rem 3rem 0rem #000, 0rem -3rem 0rem #000;
    color: white;
  }
  .title {
    margin: auto;
  }
  .product,
  .selectedButton {
    width: 90%;
  }
  .productArea {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .product {
    margin: 4rem 0;
  }
  .button,
  .selectedButton {
    margin-top: auto;
    margin-bottom: 16rem;
  }
}
