.container {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .inner {
    margin: auto;
    .parts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 36rem;
      font-weight: bold;
      margin-bottom: 72rem;
    }
    .teacher {
      margin-top: 32rem;
    }
    .buttonArea {
      margin-top: 72rem;
      padding-top: 34rem;
      display: flex;
      justify-content: center;
      align-items: center;
      .button {
        margin: 0 8rem;
      }
    }
  }
}

// TODO あとでコンポーネントにしたい
.select {
  padding: 1rem;
  border-radius: 0.5rem;
  background-color: #0a0d10;
  font-size: 2rem;
  outline: none;
  border: 0.5rem solid #e2e8f0;
  color: white;
}
