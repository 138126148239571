.container {
  width: 100%;
  height: 100%;
  position: relative;
  .contents {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    .contentsInner {
      height: 100%;
      width: 80%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .title {
        font-size: 36rem;
        font-weight: 800;
        line-height: 100%;
        margin-bottom: 48rem;
      }
      .subtitle {
        margin-bottom: 48rem;
        font-size: 24rem;
        font-weight: 800;
        line-height: 150%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      .box {
        margin-bottom: 48rem;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 175rem;
        width: 100%;
        .card {
          width: 30%;
          max-width: 300rem;
          height: 100%;
          margin: 0 1%;
        }
      }
      .buttonArea {
        display: flex;
        justify-content: center;
        align-items: center;
        .button {
          margin: 0 12rem;
        }
      }
    }
  }
}
