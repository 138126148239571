.container {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    margin-bottom: 42rem;
    text-shadow: 0px 0px 52px #fff;
    font-size: 30rem;
    font-weight: 800;
    line-height: 100%;
  }
  .subtitle {
    margin-bottom: 42rem;
    color: #000;

    text-shadow: 0px 0px 52px #fff;
    font-family: AB-countryroad;
    font-size: 71rem;
    font-style: normal;
    font-weight: 400;
    line-height: 100%; /* 71px */
    letter-spacing: 49.7rem;
    margin-left: 49.7rem;
  }
  .goal {
    width: 100%;
    max-width: 700rem;
  }
  .logo {
    position: absolute;
    left: 0;
    bottom: 0;
  }
}
