.header {
  color: white;
  font-size: 16rem;
  padding: 22rem;
  display: flex;
  align-items: center;
  font-weight: 800;
  line-height: 150%;
  .left {
    display: flex;
    align-items: center;
    .title {
      font-size: 30rem;
    }
    .button {
      margin-right: 22rem;
    }
  }
  .right {
    display: flex;
    align-items: center;
    margin-left: auto;
    .button {
      margin-left: 16rem;
    }
    .investingMira {
      font-family: VDL-LogoJrBlack;
      margin: 0 8rem;
      font-size: 48rem;
      font-weight: 950;
      line-height: 100%;
    }
    .holdingMira {
      font-family: VDL-LogoJrBlack;
      font-size: 14rem;
      font-weight: 950;
      line-height: 120%;
    }
  }
}
.icon {
  margin-left: 16rem;
  height: 32rem;
}
